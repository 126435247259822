<script setup lang="ts">
import type { CartFeedbackAddToCartProps } from './CartFeedbackAddToCart.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

defineProps<CartFeedbackAddToCartProps>()
const { cartUrl, checkoutUrl } = useRouteHelper()
const { closeAllDialogs } = useDialog()
</script>

<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.cartFeedbackAddToCart"
    force-global-theme
    close-every-sidebar
    :header-props="{
      titleText: $ts('cart.title'),
    }"
  >
    <template #body>
      <div class="cart__wrapper pb-10" data-testid="cart-wrapper">
        <div class="space-y-7">
          <div
            v-if="isAlreadyAdded"
            class="bg-primitives-grey-50 text-book-6 text-primitives-black flex items-center gap-x-2 px-4 py-2"
          >
            <DefaultIconsWarning class="h-4 w-4" />
            {{ $ts('addToCartFeedback.alreadyAdded') }}
          </div>
          <div>
            <slot name="product" />
          </div>

          <div class="flex flex-col gap-4">
            <MoleculesLinkWrapper
              :aria-label="$ts('addToCartFeedback.checkout')"
              data-testid="to-checkout"
              :full-width="true"
              :to="checkoutUrl"
            >
              <template #label>
                {{ $ts('addToCartFeedback.checkout') }}
              </template>
            </MoleculesLinkWrapper>

            <MoleculesLinkWrapper
              anatomy="secondary"
              :aria-label="$ts('cart.view')"
              :full-width="true"
              :to="cartUrl"
            >
              <template #label>
                {{ $ts('cart.view') }}
              </template>
            </MoleculesLinkWrapper>

            <MoleculesLinkWrapper
              type="button"
              anatomy="link"
              class="!text-link-5 self-center hover:cursor-pointer"
              @click.prevent="closeAllDialogs()"
            >
              <template #label>
                {{ $ts('cart.keepShopping') }}
              </template>
            </MoleculesLinkWrapper>
          </div>
        </div>

        <!-- <div class="py-lg">
          <p class="uppercase">
            {{ $ts('cart.relatedProduct') }}
          </p>
        </div> -->
        <!-- <slot name="relatedProducts" /> -->
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
